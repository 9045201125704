import axios from "axios";
class ApiOtherSettings {
    static async GetSettings(session_uuid) {
        try {
            const result = await axios.get("/api/shop/other-settings/get", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async GetPromoData(session_uuid) {
        try {
            const result = await axios.get("/api/web-api/get-promo-data", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async EditSettings(session_uuid, data) {
        try {
            const result = await axios.post("/api/shop/other-settings/edit", {
                eshopPrice: data.eshopPrice,
                esimPrice: data.esimPrice,
                esimReplacementPrice: data.esimReplacementPrice,
                esimMnpPrice: data.esimMnpPrice,
                publish: data.publish ? 1 : 0,
                uuid: data.uuid,
                overview: data.overview,
                note: data.note,
                title: data.title,
                description: data.description,
                SIMPrice: data.SIMPrice,
                keywords: data.keywords,
                url: data.url,
                promoIsActive: data.promoIsActive ? 1 : 0,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
}
export default ApiOtherSettings;
